import comment from "../../components/comment-card/index.vue";
import question from "../../components/question-card/index.vue";
import { mapKeys, snakeCase } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import StarRating from "vue-star-rating";

import { email, required } from "vuelidate/lib/validators";

import validation from "@/mixins/validation";

export default {
    name: "card-comments",
    components: {
        comment,
        StarRating,
        question,
    },

    data() {
        return {
            showForm: false,
            showQuestion: false,
            question: "",
            payload: {
                name: "",
                email: "",
                message: "",
                parentId: "",
                productId: "",
                rating: 0,
                files: [],
                created_at: "43434",
            },
            validationErrors: {},
        };
    },
    mixins: [validation],
    validations: {
        payload: {
            email: {
                email,
            },
            message: {
                required,
            },
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: "auth/isAuthenticated",
            product: "card/product",
        }),
        emailErrors() {
            return this.computeErrors("payload", "email", {
                email: "validationEmail",
            });
        },
        messageErrors() {
            return this.computeErrors("payload", "message", {
                required: "validationRequired",
            });
        },
    },
    created() {
        if (this.product) this.payload.productId = this.product.id;
    },
    methods: {
        ...mapActions({
            sendFeedback: "card/SEND_FEEDBACK",
            sendQuestion: "card/SEND_QUESTION",
        }),
        ...mapMutations({
            changeLoginPopup: "popups/CHANGE_LOGIN_POPUP",
        }),
        handleFileChange(evt) {
            let arr = Array.from(evt.target.files);
            this.payload.files = this.payload.files.concat(arr);
        },
        removeFile(file) {
            const index = this.payload.files.findIndex(e => e.name === file.name);
            this.payload.files.splice(index, 1);
        },
        parsFormData(obj) {
            let fd = new FormData();
            for (let i in obj) {
                if (i === "files") {
                    for (let j in obj[i]) {
                        fd.append(`files[${j}]`, obj[i][j]);
                    }
                } else {
                    fd.append(i, obj[i]);
                }
            }
            return fd;
        },
        transformData() {
            return mapKeys(this.payload, (value, key) => snakeCase(key));
        },
        resetForm() {
            this.payload.name = "";
            this.payload.email = "";
            this.payload.rating = 0;
            this.payload.message = "";
            this.payload.files = [];
            this.payload.created_at = "";
            this.$v.$reset();
        },
        questionSend() {
            if (this.isAuthenticated) {
                this.sendQuestion({ user_question: this.question, product_id: this.product.id }).then(() => {
                    this.question = "";
                    this.showQuestion = false;
                    this.$toasted.success(this.$t("successQuestionSend"));
                });
            } else {
                this.changeLoginPopup(true);
            }
        },
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.sendFeedback(this.parsFormData(this.transformData())).then(() => {
                    this.showForm = false;
                    this.$toasted.success(this.$t("successFeedbackSend"));
                    this.resetForm();
                });
            }
        },
    },
};

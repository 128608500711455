import _, { find, some } from "lodash";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getProductPriceTitle } from "@/helpers";

export default {
  name: 'productPrice',
  props: {
    clientPrices: {
      required: true,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      productLoading: "card/productLoading",
      product: "card/product",
      isDistributor: "profile/isDistributor",
      isProfessional: "profile/isProfessional",
      isUserWithCode: "profile/isUserWithCode",
      favourites: "favorites/whichList",
      basket: "basket/basket",
      productsWithDefaultPrices: "basket/productsWithDefaultPrices",
      user: "profile/user",
      isAuthenticated: "auth/isAuthenticated",
      showNotifyLoader: "products/showNotifyLoader",
    }),
    clientPriceTypeName() {
      return this.isProfessional ? this.$t("patient") : this.$t("cosmetologist");
    },
    isRegularUser() {
      return !(this.isUserWithCode || this.isProfessional || this.isDistributor);
    },
  },
  methods: {
    getProductPriceTitle,
    professionalHasDiscount() {
      return (this.isProfessional || this.isDistributor) && this.productHasDiscountForSomePrice();
    },
    priceHasDiscountForProfessional(productPrice) {
      /*todo - isDistributor*/
      if (!(this.isProfessional || this.isDistributor)) {
        return false;
      }

      return productPrice.hasIndividualDiscount || productPrice.hasActivePromotion;
    },
    showClientPriceColumn() {
      /*todo - isDistributor*/
      return (
          (this.isProfessional || this.isDistributor) &&
          some(this.clientPrices, ({ typeVisible }) => typeVisible)
      );
    },
    productHasDiscountForSomePrice() {
      if (this.isProfessional || this.isDistributor) {
        return some(
            this.product.productPrices.data,
            productPrice => productPrice.hasIndividualDiscount || productPrice.hasActivePromotion
        );
      }

      return some(this.product.productPrices.data, productPrice => productPrice.hasActivePromotion);
    },

    /**
     * @param {ProductPrice} productPrice
     * @return {boolean}
     */
    showClientPrice(productPrice) {
      /*todo - isDistributor*/
      return (
          (this.isProfessional || this.isDistributor) &&
          some(
              this.clientPrices,
              clientPrice => clientPrice.value === productPrice.value && clientPrice.typeVisible
          )
      );
    },
    /**
     * @param {ProductPrice} productPrice
     * @return {string}
     */
    getBasePriceForUser(productPrice) {
      if (this.isUserWithCode) {
        const clientProductPrice = find(this.clientPrices, price => price.value === productPrice.value);

        if (!clientProductPrice) {
          return "0";
        }

        return clientProductPrice.price.toFixed(0);
      }

      if (this.priceHasDiscountForProfessional(productPrice)) {
        return productPrice.oldPrice.toFixed(0);
      }

      return "0";
    },
    /**
     * @param {ProductPrice} productPrice
     * @return {string}
     */
    getClientPrice(productPrice) {
      return (productPrice.clientPrice && productPrice.clientPrice !== 0
              ? productPrice.clientPrice
              : productPrice.price
      )?.toFixed(0);
    },
    /**
     * @param {ProductPrice} productPrice
     */
    selectProductPrice(productPrice) {
      this.$emit('change-product', productPrice);
      if (!this.leftoverType) {
        this.leftoverType = productPrice.leftoverType.data;
      }

      this.selectedProductPrice = productPrice;

      if (this.isSelectedPrice(productPrice)) {
        return;
      }

      this.leftoverType = productPrice.leftoverType.data;

      this.setSelectedCount(this.selectedCount, productPrice);
      this.setPriceValueToQuery(productPrice.value);
    },
    setSelectedCount(count, productPrice = null) {
      this.selectedCount = 0;
      const setQuantity = Math.min(
          Math.max(Number.parseFloat(count || "1"), 1),
          (productPrice || this.selectedProductPrice).quantity
      );

      return (this.selectedCount = Math.ceil(setQuantity));
    },
    setPriceValueToQuery(value) {
      this.$router.replace({ query: { value } });
    },
    isSelectedPrice(productPrice) {
      return parseInt(this.$route.query.value) === parseInt(productPrice.value);
    },
  }
}